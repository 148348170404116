<template>
  <div class="img">
      <img :src="bgImg" alt="">
  </div>
</template>

<script>
export default {
    props:{
        bgImg:{
            typeof:String,
            default(){
               return '../../assets/img/headerImg/Banner002187-ban1_wap.jpg'}
        }
    },
    data() {
        return {
            // imgurl: require(this.bgImg),/
        };
    },
    created(){
      
    },
    watch:{
       
    }
}
</script>

<style lang="less" scoped>
    .img{
        width: 100%;
        min-height: 125px;
        img{
            width: 100%;
            min-height: 125px;
        }
    }
</style>>

